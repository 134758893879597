// uia header.less


// Test-host banner
body[class*="uia-test.uio.no"] {
  &::before {
    background-color: #faf;
  }
}

#head-wrapper {
  #header-tools {
    font-variation-settings: 'wght' 600;
    color: var(--uia-rod-2);
  }
  .logo {
    @media (@screen-below-medium) {
      width: 190px;
    }
    &.museum {
      background-image: url("../images/logo-naturmuseum.svg");
      width: 290px;
      @media (@screen-below-medium) {
        width: 240px;
      }
      &.en {
        background-image: url("../images/logo-naturmuseum-en.svg");
        width: 340px;
        @media (@screen-below-medium) {
          width: 270px;
        }
      }
    }
  }
  // Place language select inside menu on small screen
  @media (@screen-below-medium) {
    #header-tools {
      grid-template-columns: 1fr 1fr; // One less, since lang is taken out
    }
    #header-language {
      position: absolute;
      right: 0;
      top: 100px;
      transition: all .5s;
    }
  }
  #header-language {
    > span {
      padding: 4px 6px;
      border-width: 3px;
      border-radius: var(--radius);
    }
  }
  .toggle-search {
    &::before {
      width: 30px;
      height: 30px;
    }
  }
  #header-search-form {
    input,
    button {
      padding: 7px 20px;
      &:hover {
        background-color: #0002;
      }
    }
    input {
      font-variation-settings: 'wght' 400;
      &::placeholder {
        color: #fff;
      }
    }
  }
  #header-language-tooltip {
    border-radius: var(--radius);
  }
  #header-language-tooltip-close {
    border-radius: 100%;
  }
}
@media (@screen-below-medium) {
  // Show language-select only when menu open
  body:not(.sidebar-menu-wrapper-visible) {
    #head-wrapper {
      #header-language {
        display: none;
      }
    }
  }
  // Move language-select down if search open
  body.header-search-visible {
    #header-language {
      margin-top: 100px;
    }
  }
}



// Header elements switch to white when search or menu open or if frontpage hero element is dark

/*
Removed :has selector Sep. 2024 due to weak FF support.
Using JS to set class since this is an important element.
*/

//body:has(.hero[class*=-dark]),
body.has-hero.hero-dark,
body.header-search-visible,
body.sidebar-menu-wrapper-visible {
  #head-wrapper {
    background-color: var(--uia-rod-2);
    #header-tools {
      color: white;
    }
    .logo {
      background-image: url("../images/logo-white.svg");
      // English logo
      &.en {
        background-image: url("../images/logo-white-en.svg");
      }
      // Museum logo
      &.museum {
        background-image: url("../images/logo-naturmuseum-white.svg");
        &.en {
          background-image: url("../images/logo-naturmuseum-en-white.svg");
        }
      }
    }
    .toggle-search::before {
      background-image: url("../images/search-white.svg");
    }
    .sidebar-menu-toggle::before {
      background-image: url("../images/menu-white.svg");
    }
  }
}
// When dark hero present..
//body:has(.hero[class*=-dark]), // See comment above
body.has-hero.hero-dark {
  // No bg on header and menu
  #head-wrapper,
  .sidebar-menu-wrapper {
    background-color: transparent;
  }
}