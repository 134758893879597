// uia quiz.less


form.quiz {
  max-width: var(--width-text-max);
  fieldset {
    background-color: var(--uia-brun-1);
    border-radius: var(--radius);
    padding: 25px;
    margin-bottom: 40px;
  }
  img {
    margin: 0 0 25px 0;
  }
  p {
    font-size: 3rem;
    line-height: 1.3;
    font-variation-settings: 'wght' 300;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    line-height: 1.3;
    label {
      display: inline-flex;
      gap: 10px;
      align-items: center;
      background-color: white;
      border-radius: var(--radius2);
      padding: 10px 20px 10px 15px;
      margin: 10px 8px 10px 0;
      input {
        accent-color: var(--uia-rod-1);
      }
      &:not(:has(input:disabled)) {
        cursor: pointer;
        &:hover {
          background-color: #0001;
        }
      }
      &:has(input:checked) {
        color: white;
        &,
        &:hover {
          background-color: var(--uia-rod-2);
        }
      }
    }
    // Strong used to indicate correct/incorrect
    strong {
      display: none;
    }
  }
  button {
    color: white;
    font-size: 2.4rem;
    padding: 10px 25px;
    &,
    &:hover {
      background-color: var(--uia-rod-2);
    }
  }
  &.submitted {
    li {
      label {
        &:has(input:checked) {
          + strong {
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            gap: 10px;
            &.correct {
              &::before {
                content: "";
                flex-shrink: 0;
                background: url("../images/smile.svg") no-repeat center;
                background-size: contain;
                border-radius: 100%;
                width: 30px;
                height: 30px;
              }
            }
          }
        }
      }
    }
    button {
      display: none;
    }
  }
}