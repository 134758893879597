// uia filters-form.less


// Shared styles for exchange and person list page filter
#vrtx-uh-exchange-listing,
#vrtx-uh-person-listing {
  @media (@screen-large) {
    #vrtx-main-content {
      padding-left: 38%;
      &::after {
        content: "";
        display: block;
        clear: left;
      }
    }
    h1,
    #filters-form,
    .vrtx-introduction {
      margin-left: -61%;
    }
    h1 {
      margin-bottom: 35px;
    }
    .vrtx-introduction {
      margin-top: -10px;
    }
    #filters-form {
      position: sticky;
      top: -15px;
      float: left;
      width: 55%;
      // Safety-valve
      max-height: 100vh;
      overflow: auto;
    }
  }
  #filters-form {
    background-color: var(--uia-brun-1);
    border-radius: var(--radius);
    padding: 25px;
    fieldset {
      max-width: var(--width-text-max);
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      legend {
        margin-bottom: 5px;
      }
      ul {
        font-size: var(--font-size-small);
        border: 1px solid #0004;
        border-radius: var(--radius);
        padding: 10px 15px;
        margin: 0;
        overflow: auto;
        scrollbar-color: #0008 transparent;
        // scrollbar-width: thin;
      }
      li {
        width: auto;
        label {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    input:hover,
    input:hover + label,
    label:hover,
    button:hover {
      background-color: white;
    }
  }
}



// Exchange list page filter
#vrtx-uh-exchange-listing {
  #filters-form {
    #vrtx-filter-type {
      margin-bottom: 0;
    }
    ul {
      max-height: 195px; // Set so text is cut in half
    }
    #vrtx-filter-agreement {
      display: none; // Hide filter for avtale (Erasmus etc)
    }
    // Submit button not in use. Submits on checkbox click, using JS.
    button[type=submit] {
      display: none;
    }
  }
}



// Person list page filter
#vrtx-uh-person-listing {
  #filters-form {
    // Text search filter
    #vrtx-filter-query {
      input,
      button {
        line-height: 1;
      }
      label {
        .invisible;
      }
    }
    ul {
      @media (@screen-large) {
        max-height: calc(100vh - 300px);
      }
      @media (@screen-below-large) {
        max-height: 195px; // Set so text is cut in half
      }
    }
  }
}