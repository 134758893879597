// x-media-fullscreen.less

/*
Fullscreen media (images and video) in article-type pages.
Sticky effect if figcaption present.
Chaining if multiple follow each other.

Script in x-media-wide.js
*/

#vrtx-structured-article,
#vrtx-structured-event {

  // Prevent 100vw images from adding horizontal scrollbar on windows
  overflow-x: hidden;

  .vrtx-article-body {

    // Fullscreen media, CK4 and CK5 variants
    p.image-center,
    .image-center figure,
    figure:has(.user-fullscreen),
    figure.background-video {

      @media (@screen-large) {
        position: relative;
        z-index: 1;
        margin: 40px 0;
        float: none;
        width: auto;
        // Background video component. Revert from relative to follow sticky behaviour
        .vrtx-background-video {
          position: static;
        }
        img,
        video,
        figcaption {
          transition: opacity 1s;
        }
        img,
        video {
          max-width: none;
          width: 100vw;
          object-fit: cover;
          // Set left pos at browser edge
          // Overflow viewport left spacing and content indent (below screen-oversized)
          // Spacing default 50 + indent default 200
          margin-left: -250px;
        }
        figcaption {
          position: absolute;
          z-index: 2;
          left: calc(50vw - 30%);
          top: 50%;
          background: white;
          padding: 40px;
          margin: 0;
          font-size: inherit;
          color: inherit;
          width: 500px;
        }
      }
      @media (@screen-oversized) {
        img,
        video {
          // Set left pos at browser edge
          // First move left pos to center of content area (default 1400/2 = 700)
          // Content indented (default 200), so subtract that
          // Then subtract half of viewport width
          margin-left: calc(500px - 50vw);
        }
      }
    }
  }
}

// Fullscreen media, escaping vortex admin preview
body:not(.vrtx-mode-preview) {
  &#vrtx-structured-article,
  &#vrtx-structured-event {

    .vrtx-article-body {
      @media (@screen-large) {

        p.image-center,
        .image-center figure,
        figure:has(.user-fullscreen),
        figure.background-video {

          img,
          video {
            // Media height = viewport height
            height: 100vh;
          }
          &:has(figcaption) {
            // Figure with caption is 2x viewport height. This determines scroll distance.
            height: 200vh;
          }
          &.sticky {
            img,
            video {
              position: fixed;
              top: 0;
              z-index: 1;
            }
            .pause-video {
              position: fixed;
              left: auto;
              z-index: 1;
            }
          }
          // When scrolled past image (= half of figure height),
          // image is changed from fixed to absolute bottom of figure
          &.scrolled-past {
            img,
            video {
              position: absolute;
              bottom: 0;
            }
            figcaption {
              opacity: 0;
            }
          }
          // Chaining if directly follow each other.
          // Subtract media/screen height and margin between items.
          &:has(figcaption) + figure:has(.user-fullscreen):has(figcaption) {
            margin-top: calc(-100vh - 40px);
            img,
            video {
              opacity: 0;
            }
          }
          &.scrolled-past + figure:has(.user-fullscreen):has(figcaption) {
            img,
            video {
              opacity: 1;
            }
          }
        }
        // Chaining for CK4, which has div outer container
        .image-center {
          &:has(figcaption) + .image-center:has(figcaption) {
            margin-top: calc(-100vh - 40px);
            img,
            video {
              opacity: 0;
            }
          }
          &:has(.scrolled-past) + .image-center:has(figcaption) {
            img,
            video {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
