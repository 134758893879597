// uia for-ansatte.less

/*
For-ansatte pages have their own header, footer, menu and color profile
Unique body class follows config in vrtx/decorating/config.txt
Header, footer and supplementary menu set up in vrtx/decorating/resources
*/

body.intranett {
  // Color profile
  --color-ansatte: var(--uia-bla-2);
  --color-ansatte-light: var(--uia-bla-1);
  --color-neutral-tint-95: var(--color-ansatte-light);

  --radius5: 100px;

  // Remove frontpage row color selector
  .vrtx-edit-row.vrtx-frontpage-row {
    .admin-box-row-menu__element.menu__element_row_change_color {
      display: none;
    }
  }

  // Header
  #head-wrapper {
    background-color: var(--color-ansatte);
    @media (@screen-medium) {
      border-bottom-left-radius: var(--radius5);
    }
    * {
      color: white;
    }
    #header {
      padding-bottom: 110px;
      @media (@screen-below-medium) {
        padding-bottom: 60px;
      }
    }
    .logo {
      background-image: url("../images/logo-name-only-white.svg");
      width: 100px;
      @media (@screen-below-medium) {
        width: 90px;
      }
    }
    .for-employees-link {
      .mixin-h2;
      position: absolute;
      text-decoration: none;
      margin: 0;
      top: 100px;
      @media (@screen-below-medium) {
        top: 70px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    .toggle-search::before {
      background-image: url("../images/search-white.svg");
    }
    .sidebar-menu-toggle::before {
      background-image: url("../images/menu-white.svg");
    }
    #header-language {
      @media (@screen-below-medium) {
        top: 70px;
        margin: 0;
      }
    }
    #header-search-form {
      input {
        background-color: white;
        &,
        &::placeholder {
          color: var(--color-ansatte);
        }
      }
    }
  }
  // Unique for the-frontpage
  &.the-frontpage {
    #header-search-form {
      display: block;
    }
    #main {
      margin-top: 50px;
    }
  }
  // Menu
  .sidebar-menu-wrapper {
    background-color: var(--color-ansatte);
    color: white;
    @media (@screen-medium) {
      border-bottom-left-radius: var(--radius5);
    }
    a {
      &:hover {
        background-color: #0005;
      }
    }
    .sidebar-global-menu {
      display: block;
      padding-top: 0;
      max-width: none;
    }
    .vrtx-tab-menu {
      display: none;
    }
    #supplementary-menu {
      display: grid;
      gap: 40px;
      @media (@screen-large) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (@screen-below-large) {
        gap: 20px;
      }
      div {
        width: fit-content;
        ul {
          border-bottom: 1px solid #fff3;
          padding-bottom: 10px;
          margin-bottom: 5px;
          @media (@screen-below-large) {
            display: none;
          }
        }
        > a {
          &:first-child {
            .mixin-h3;
            margin: 0;
          }
          &:last-child {
            font-variation-settings: 'wght' 600;
            font-size: var(--font-size-tiny);
            @media (@screen-below-large) {
              display: none;
            }
          }
        }
      }
    }
  }
  // When search open (includes frontpage)
  &.header-search-visible,
  &.the-frontpage {
    #head-wrapper {
      #header {
        padding-bottom: 170px;
      }
    }
  }
  // When menu open
  &.sidebar-menu-wrapper-visible {
    #head-wrapper {
      border-radius: 0;
    }
  }
  // Footer
  #footer-wrapper {
    background-color: var(--color-ansatte);
    .row {
      &::after {
        content: "For ansatte";
        width: auto;
        bottom: 10px;
      }
    }
  }
  &.en {
    #footer-wrapper {
      background-color: var(--color-ansatte);
      .row {
        &::after {
          content: "For Employees";
        }
      }
    }
  }
  // Breadcrumbs
  #vrtx-breadcrumb-wrapper {
    margin-top: 10px;
  }
  // Headings
  h1,
  .media-wide.intro .vrtx-box-content h2 {
    color: var(--color-ansatte);
  }
  // Buttons
  .button-loud,
  .button-large {
    background-color: var(--color-ansatte);
  }
  // Article body
  .vrtx-article-body {
    border-bottom-color: inherit;
  }
  // Feed and event items (when in columns)
  .vrtx-feed li,
  [class*=column-feed] .vrtx-event-component-item {
    background-color: var(--color-ansatte-light);
  }
  .row-all-colored {
    .vrtx-feed li,
    [class*=column-feed] .vrtx-event-component-item {
      background-color: white;
    }
  }
  // Social icon colors
  .vrtx-social-components a,
  .vrtx-social-list a {
    filter: saturate(0);
  }
  // Link-boxes
  .vrtx-frontpage-box.link-boxes {
    .vrtx-box-content {
      display: grid;
      gap: var(--space);
      @media (@screen-large) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      > div {
        background-color: var(--color-ansatte-light);
        border-radius: var(--radius2);
        padding: 25px;
        > a:first-child {
          .mixin-h3;
          display: block;
          margin-bottom: 20px;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        > a:last-child {
          .mixin-arrow-link;
          font-size: var(--font-size-small);
        }
      }
      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 15px;
        font-variation-settings: 'wght' 600;
        font-size: var(--font-size-small);
      }
      li {
        display: inline-block;
        margin: 0 5px 5px 0;
        a {
          display: block;
          padding: 2px 10px;
          background-color: white;
          border: 2px solid transparent;
          border-radius: var(--radius);
          //text-decoration-thickness: 1px;
          text-decoration: none;
          transition: all .3s;
          &:hover {
            border-color: inherit;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  // Link-boxes in colored row
  .row-all-colored {
    .vrtx-frontpage-box.link-boxes {
      .vrtx-box-content {
        > div {
          background-color: white;
        }
        li {
          a {
            background-color: var(--color-ansatte-light);
          }
        }
      }
    }
  }
  // Login-link: JS-created for restricted content
  // (Restricted-class is added manually to frontpage boxes and handled by JS)
  .login-link {
    .mixin-button-link;
  }
  // No-access: Class added with JS to restricted boxes when verified that not logged in
  .no-access {
    .vrtx-more {
      display: none;
    }
    // Hide any content except login-link (assuming error message)
    .vrtx-box-content *:not(.login-link) {
      display: none;
    }
  }
  // Messages feed
  .vrtx-messages-main-content-wrapper {
    .vrtx-messages-header {
      h2 {
        font-size: inherit;
        margin: 0;
        // Title hidden. Using box-title
        a:first-child {
          display: none;
        }
      }
    }
    .items {
      gap: 30px;
      li {
        position: relative; // For link cover
        padding: 20px 25px;
        background: none;
        border: 2px solid;
      }
      .item-title {
        margin-bottom: 10px;
        // Link cover whole element
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
      .vrtx-authors-prefix {
        display: none;
      }
      .vrtx-authors {
        font-size: var(--font-size-small);
        font-variation-settings: 'wght' 600;
      }
      .published-date {
        margin: 0;
      }
    }
  }
  // Messages-row
  // Row-class used on main intranet frontpage.
  .messages-row {
    // If colored row
    &.row-all-colored {
      background-color: var(--uia-brun-1);
    }
    .column-wrapper {
      @media (@screen-large) {
        grid-template-columns: 1fr 1fr 1fr; // Triple to align with link-boxes
      }
    }
    .messages-box {
      @media (@screen-large) {
        // Use 2 of 3 columns, for alignment with triple
        grid-column: span 2;
        // Add left-spacing to box after, to align with link-boxes content (padding)
        & + .vrtx-frontpage-box {
          margin-left: 25px;
        }
      }
    }
    .vrtx-messages-main-content-wrapper {
      display: grid;
      gap: var(--space) 80px;
      @media (@screen-large) {
        grid-template-columns: auto 1fr;
      }
    }
  }
  #vrtx-message-listing-create,
  .vrtx-message-listing-create {
    background-color: var(--color-ansatte);
    color: white;
    border-color: transparent;
    &::after {
      background-image: url("../images/arrow-white.svg");
    }
  }
  // Messages frontpage
  // Dedicated frontpage with feed only. Class set on row.
  // This to avoid using listing-page. See js for explanation
  .all-messages-page {
    margin-top: 35px; // Tweak spacing to h1
    .vrtx-messages-main-content-wrapper {
      .vrtx-messages-header {
        margin-bottom: 30px;
      }
      // No link to all from this page
      .vrtx-more {
        display: none;
      }
    }
  }
  // Message listing page. Not in use. Bypassed using redirect included in source on page.
  // For some reason, I still felt like styling this properly.
  &#vrtx-message-listing {
    .vrtx-authors {
      font-size: var(--font-size-small);
      color: var(--color-neutral-tint-25);
    }
  }
  // Hide breadcrumbs to messages listing page
  &#vrtx-message,
  &:has(.all-messages-page) {
    #vrtx-breadcrumb span {
      &.vrtx-breadcrumb-before-active {
        display: none;
      }
    }
  }
  // Message main page
  &#vrtx-message {
    // Message main text
    #vrtx-field-message {
      @media (@screen-medium) {
        font-size: 2.2rem;
        font-variation-settings: 'wght' 300;
        h3 {
          font-size: 2.6rem;
        }
        // Font-size exceptions (not sure if needed)
        .button,
        .button-large,
        .button-loud,
        table {
          font-size: var(--font-size-base);
        }
      }
    }
    .vrtx-authors {
      margin-top: calc(3 * var(--space));
      font-variation-settings: 'wght' 600;
    }
    .vrtx-authors-prefix {
      display: none;
    }
    .vrtx-date-info {
      margin-top: 0;
    }
    .published-date-label {
      font-variation-settings: 'wght' 400;
    }
    .vrtx-comments {
      .add-comment-header {
        margin-bottom: 10px;
      }
      .submit {
        margin-top: 10px;
      }
    }
  }
}