// uia study-program.less


// Context box. NOT IN USE
// Present on various pages in studies context. Typically contains link to programme frontpage.
// Additional class "vrtx-context-box-linked" can be targetted if necessary to separate.
.vrtx-context-box {
  display: none;
}



// Study program finder
#study-program-finder {
  // Filter
  .studies-filter {
    .inner {
      @media (@screen-large) {
        top: -15px;
      }
    }
    h2 {
      margin-bottom: 7px;
    }
    .text-filter,
    .inner {
      border-radius: var(--radius);
    }
    .text-filter {
      @media (@screen-large) {
        min-height: 139px; // Tweaked to align with list
      }
      input {
        line-height: 1; // Prevent height increase from font increase
      }
      button {
        width: 60px;
      }
    }
    .checkbox-filter {
      label {
        strong {
          font-variation-settings: 'wght' 400;
        }
      }
    }
    .button-filter label {
      border-radius: var(--radius2);
    }
    .button-filter label,
    .dropdown-filter select {
      padding: 6px 12px;
    }
    .button-filter {
      label {
        border-width: 2px;
        font-variation-settings: 'wght' 500;
        &:focus-within {
          box-shadow: 0 0 0 2px white, 0 0 0 4px var(--uia-color-text);
        }
        &.selected {
          background-color: var(--uia-rod-2);
        }
      }
    }
  }
  // Studies feedback
  .studies-feedback {
    background: none;
    padding: 0 0 0 20px;
    .studies-message {
      h2 {
        font-variation-settings: 'wght' 400;
      }
    }
    .clear-facets {
      padding: 10px 25px;
      &:hover {
        background-color: white;
      }
    }
  }
  // Studies list
  .studies-list {
    a {
      border-radius: var(--radius);
      overflow: hidden;
    }
    img {
      border-radius: 0;
      @media (@screen-medium) {
        width: 160px;
      }
      @media (@screen-below-medium) {
        width: 80px;
        height: auto;
        align-self: stretch;
      }
    }
  }
}



// Shared styles for related page templates
body#vrtx-fs-studieprogram-frontpage,
body#vrtx-fs-studieprogram-document,
body#vrtx-fs-emne {
  // Move main page-wrapper dimensions to contained elements
  #main {
    margin: 0;
    padding: 0;
    max-width: none;
  }
  // Add main wrapper dimensions
  #vrtx-breadcrumb-wrapper,
  .page-intro .inner,
  .page-main {
    .mixin-page-wrapper;
  }
  // Page bg color
  #vrtx-content {
    background-color: var(--uia-brun-1);
    padding-bottom: 1px; // Hack to get bottom space without overflow hidden
  }
  #vrtx-breadcrumb-wrapper {
    @media (@screen-large) {
      margin-bottom: 40px;
    }
  }
  // Message box that shows status for programmes that don't admit students
  .vrtx-context-message-box {
    margin-bottom: var(--space);
    .content-wrapper {
      display: grid;
      grid-template-columns: 30px auto;
      gap: 10px;
      width: fit-content;
      background-color: var(--uia-gul-2);
      padding: 15px 25px 15px 15px;
      // Icon
      &::before {
        content: "";
        background: url("../images/alert.svg") no-repeat center;
        background-size: contain;
      }
    }
  }
  // Page intro
  .page-intro {
    background-color: white;
    margin-bottom: var(--space);
    overflow: hidden;
    @media (@screen-large) {
      border-bottom-left-radius: var(--radius5);
      padding-bottom: 60px;
    }
  }
  h1 {
    max-width: var(--width-soft-max);
    @media (@screen-medium) {
      font-size: 5rem;
      line-height: 1.2;
    }
  }
  .page-main {
    margin-bottom: calc(3 * var(--space));
  }
}



// Facts box, list of study attributes: points, duration etc.
#vrtx-fs-studieprogram-facts,
#vrtx-fs-emne-facts {
  margin-bottom: var(--space);
  // Heading. Not in use.
  // Will generate uu level error if made visible to screen reader.
  h2,
  h4 {
    display: none;
  }
  dl {
    display: grid;
    gap: 20px 40px;
  }
  dt {
    font-variation-settings: 'wght' 400;
  }
  dd {
    .mixin-h3;
    margin: 0;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}
#vrtx-fs-studieprogram-facts {
  dl {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}
#vrtx-fs-emne-facts {
  // Auto column widths due to more varying content than studieprogram-facts
  dl {
    grid-template-columns: repeat(3, auto);
    @media (@screen-below-large) {
      grid-template-columns: repeat(2, auto);
    }
    @media (@screen-below-medium) {
      grid-template-columns: 1fr;
    }
  }
}



// Study program frontpage
body#vrtx-fs-studieprogram-frontpage {
  // Page intro
  .page-intro {
    padding-bottom: 0;
    .inner {
      @media (@screen-large) {
        display: flex;
        justify-content: space-between;
        gap: var(--space);
      }
    }
  }
  .study-grade,
  .study-location {
    display: block;
  }
  .study-location {
    margin-bottom: 25px;
    &::before {
      content: "";
      background: url("../images/map-pin-red.svg") no-repeat center;
      padding: 3px 11px;
      margin-right: 10px;
    }
  }
  .intro-text {
    max-width: var(--width-soft-max);
    @media (@screen-large) {
      padding-bottom: 60px;
      //
      .vrtx-introduction {
        margin: 0;
      }
    }
  }
  .vrtx-introduction {
    @media (@screen-medium) {
      font-size: 2.4rem;
    }
  }
  .vrtx-introduction-image {
    display: flex; // To stretch img to height
    margin: 0;
    img {
      border-radius: 0;
    }
    .vrtx-imagetext {
      display: none; // No support for this
    }
    @media (@screen-large) {
      flex-shrink: 0;
      flex-basis: 40%;
      img {
        object-fit: cover;
      }
    }
  }
  // Box-style mixin
  .mixin-box {
    background-color: white;
    border-radius: var(--radius);
    padding: 30px 25px;
    margin-bottom: calc(2 * var(--space));
    @media (@screen-large) {
      position: relative;
      padding-left: 40%;
      min-height: 150px;
      --width-text-max: none;

      // H2 pulled out of content area
      // Min-height and matching margin-bottom keeps alignment for up to two lines of text
      h2 {
        max-width: 60%;
        min-height: 150px;
        margin: 60px 0 -150px -65%;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    @media (@screen-below-large) {
      h2 {
        margin: 50px 0 20px;
        &:first-child {
          margin-top: -5px;
        }
      }
    }
  }
  // Primary data and actions area
  .primary-data-and-actions {
    margin-bottom: calc(2 * var(--space));
  }
  #vrtx-fs-studieprogram-search-button {
    margin-bottom: 20px; // In case restetorg info after
    a {
      .button-loud;
      min-width: 200px;
    }
  }
  // Detailed description area
  .detailed-description {
    .mixin-box;
  }
  // Feed and event-component list-items
  .vrtx-feed li,
  .vrtx-event-component-item {
    background-color: var(--uia-gronn-1);
  }
  // Main editor content
  #vrtx-fs-studieprogram-content {
    // Match spacing with mixin-box h2 to appear as continuous content
    margin-bottom: 60px;
    @media (@screen-below-medium) {
      margin-bottom: 50px;
    }
  }
  .vrtx-media-player {
    margin-bottom: 30px;
  }
  // Plan links
  .plan-links {
    a {
      display: block;
      width: fit-content;
      margin-bottom: 25px;
    }
  }
  #vrtx-fs-studieprogram-stories {
    .mixin-box;
    // Feed revert to vertical variant (container has three-column- class)
    .vrtx-feed {
      ul {
        grid-template-columns: unset;
      }
      @media (@screen-medium) {
        li {
          display: flex;
        }
        .vrtx-list-item-content {
          padding-left: 0;
        }
        .vrtx-image {
          max-width: 47%;
        }
        img {
          height: 100%;
        }
      }
    }
  }
  #vrtx-fs-studieprogram-contact {
    margin-bottom: var(--space);
    .info {
      display: grid;
      gap: 40px;
      grid-template-columns: repeat(3, 1fr);

      @media (@screen-below-xl) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (@screen-below-medium) {
        grid-template-columns: 1fr;
      }
      .vrtx-contact-component {
        margin: 0;
      }
      br {
        display: none;
      }
    }
  }
}



// Study program document and subject pages, shared styles
body#vrtx-fs-studieprogram-document,
body#vrtx-fs-emne {
  .main-content {
    @media (@screen-large) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: start;
      gap: calc(2 * var(--space));
    }
  }
  .connected-links {
    margin-bottom: var(--space);
    a {
      margin: 0 20px 20px 0;
    }
  }
  // TOC
  // Outer container (contains heading and #toc)
  .toc {
    background-color: white;
    border-radius: var(--radius);
    padding: 25px;
    @media (@screen-large) {
      position: sticky;
      top: -15px; // Cut top radius
      max-height: calc(100vh + 30px); // Cut radius at top and bottom
      overflow: auto;
    }
    @media (@screen-below-large) {
      margin-bottom: calc(2 * var(--space));
    }
  }
  // Toc header
  .vrtx-toc-header {
    font-size: inherit;
    line-height: inherit;
    margin: 0 0 15px 0;
  }
  // Toc main
  #toc {
    margin: 0;
    ul {
      list-style: none;
    }
    > ul > li > a {
      .mixin-arrow-marker;
      font-variation-settings: 'wght' 600;
    }
    ul ul {
      padding-left: 15px;
      margin-bottom: 5px;
    }
  }
  // Main text
  .main-text {
    max-width: var(--width-text-max);
    h2 {
      margin: 50px 0 20px;
      &:first-child {
        margin-top: 0;
      }
    }
    li {
      p {
        margin: 0;
      }
    }
  }
}



// Study-plan page
body#vrtx-fs-studieprogram-document {
  // Course list (in main-text)
  .course-list {
    list-style: none;
    padding: 0;
    margin: 10px 0 40px 0;
    li {
      position: relative;
      overflow: hidden;
      margin-bottom: 20px;
      padding-right: 140px; // Match points width
      border-radius: var(--radius);
      border: 2px dashed;
      &.mandatory {
        border-style: solid;
      }
      @media (@screen-below-medium) {
        padding-right: 80px;
      }
    }
    .course-link,
    .course-description {
      display: block;
      padding: 20px 20px 25px 30px;
      @media (@screen-below-medium) {
        padding: 15px 15px 20px 20px;
      }
    }
    .course-not-linked-info {
      display: none;
    }
    .course-link {
      background-color: white;
      text-decoration: none;
      .course-description {
        padding: 0; // Remove padding when in link
      }
      .course-info {
        text-decoration: underline;
      }
      &:focus {
        outline-offset: -10px;
      }
      &:hover {
        .course-info {
          text-decoration: none;
        }
      }
    }
    .course-info,
    .course-study-points {
      @media (@screen-medium) {
        .mixin-h3;
      }
      @media (@screen-below-medium) {
        font-variation-settings: 'wght' 600;
      }
    }
    .course-info {
      display: block;
      margin: 2px 0 0 0;
    }
    .course-study-points {
      position: absolute;
      right: 0;
      top: 0;
      background-color: #0001;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 140px; // Match parent right padding

      span:first-child:after {
        content: "\00a0";
      }

      @media (@screen-below-medium) {
        width: 80px;
      }
    }
  }
}



// Study-plan page directions
.vrtx-fs-study-model {
  form[id*="direction-form-"] {
    label {
      background-color: #0001;
      border-radius: var(--radius2);
      padding-right: 20px;
      &::before {
        box-shadow: 0 0 0 2px var(--uia-rod-2);
      }
    }
    input:focus + label {
      outline-color: var(--uia-rod-2);
    }
    input:checked + label {
      background-color: var(--uia-rod-2);
      &::before {
        background: none;
      }
    }
  }
  h3 {
    .heading-message {
      font-variation-settings: 'wght' 400;
    }
  }
}
