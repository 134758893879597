// uia local.less


// X-styles
@import "../../../../../../../lib/x1/css/x-0.less";

// Font (Adobe)
@import url("https://use.typekit.net/fxm1ico.css");

// Local imports
@import "base.less"; // Variables etc.
@import "typography.less";
@import "header.less";
@import "footer.less";
@import "general.less"; // For styles that don't have/need their own file.
@import "feeds-and-lists.less";
@import "frontpage-color-system.less";
@import "naturmuseum.less";
@import "study-program.less";
@import "filters-form.less"; // Used on exchange and people list pages
@import "exchange.less";
@import "people.less";
@import "publications.less";
@import "research-groups-and-projects.less";
@import "quiz.less";
@import "for-ansatte.less";


