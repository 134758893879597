// uia frontpage-color-system.less


.grid-container[class*=grid-color] {
  // Default color theme
  --color-light: var(--uia-brun-1);
  --color-dark: var(--uia-color-text);
  --arrow: url("../images/arrow.svg");

  // For any grid-container with specified color:
  // Remove background and reset spacing to same as non-colored row
  background-color: transparent;
  padding: 0;

  // Elements that can get color change
  // Also supports some contained elements, such as read-more and button
  &.hero,
  .img-special-left,
  .img-special-right,
  .box-special,
  .navigation-links li a {
    background-color: var(--color-light);
    color: var(--color-dark);
  }
  // Link special: no bg, only text color
  .link-special {
    color: var(--color-dark);
  }
  // Arrow colors
  .link-special a::after,
  .navigation-links li a::after {
    background-image: var(--arrow);
  }
  // For any contained read-more or button link
  &.hero,
  .img-special-left,
  .img-special-right,
  .box-special {
    // Arrow colors
    .read-more::after,
    .button::after {
      background-image: var(--arrow);
    }
    // Button-loud gets specified bg color but retains white text and arrow
    .button-loud,
    .button-large {
      background-color: var(--color-dark);
    }
  }
  // Dark bg
  &[class*=-invert],
  &[class*=-dark] {
    &.hero,
    .img-special-left,
    .img-special-right,
    .box-special {
      // Button-loud reverts to white bg with default text and arrow color
      .button-loud,
      .button-large {
        background-color: white;
        color: var(--uia-color-text);
        &::after {
          background-image: url("../images/arrow.svg");
        }
      }
    }
    // Navigation-links icons invert to white
    .navigation-links li img {
      filter: invert(1);
      opacity: 1;
    }
    // Link-special no support for dark. Gets default text and arrow color
    .link-special {
      color: var(--uia-color-text);
      a::after {
        background-image: url("../images/arrow.svg");
      }
    }
  }
  // Color variables
  &.grid-color-c2 {
    --color-light: var(--uia-rod-1);
    --color-dark: var(--uia-rod-2);
    --arrow: url("../images/arrow-red.svg");
  }
  &.grid-color-c2-dark {
    --color-light: var(--uia-rod-2);
    --color-dark: var(--uia-rod-1);
    --arrow: url("../images/arrow-red-light.svg");
  }
  &.grid-color-c3 {
    --color-light: var(--uia-gronn-1);
    --color-dark: var(--uia-gronn-2);
    --arrow: url("../images/arrow-green.svg");
  }
  &.grid-color-c3-dark {
    --color-light: var(--uia-gronn-2);
    --color-dark: var(--uia-gronn-1);
    --arrow: url("../images/arrow-green-light.svg");
  }
  &.grid-color-c4 {
    --color-light: var(--uia-bla-1);
    --color-dark: var(--uia-bla-2);
    --arrow: url("../images/arrow-blue.svg");
  }
  &.grid-color-c4-dark {
    --color-light: var(--uia-bla-2);
    --color-dark: var(--uia-bla-1);
    --arrow: url("../images/arrow-blue-light.svg");
  }
}
