// uia people.less


// Contact person component
.vrtx-contact-component {
  // Var used to calculate sizes based on image-width
  --img-width: 140px;
  @media (@screen-below-large) {
    --img-width: 90px;
  }

  position: relative;
  padding-left: calc(var(--img-width) + 25px);
  min-height: var(--img-width); // Aspect ratio 1/1, so height = width
  margin-top: 15px;
  margin-bottom: var(--space);
  font-size: var(--font-size-small);

  img,
  &:not(:has(img))::before {
    position: absolute;
    left: 0;
    width: var(--img-width);
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 100%;
    margin: 0; // Override for when used in article-body
  }
  &:not(:has(img))::before {
    content: "";
    background-color: #0001;
  }
  .name,
  .title {
    font-variation-settings: 'wght' 600;
  }
  .name {
    font-size: 2rem;
    line-height: 1.3;
    @media (@screen-large) {
      padding-top: 10px;
    }
  }
  .title {
    @media (@screen-large) {
      margin-bottom: 10px;
    }
  }
  .contact {
    margin: 0;
    dt {
      // Remove description list titles, such as "email" and "phone"
      display: none;
    }
  }
}



// Frontpage box class for column layout
.vrtx-frontpage-box {
  &.contact-columns {
    .vrtx-box-content {
      display: grid;
      gap: 40px;
      grid-template-columns: repeat(3, 1fr);

      @media (@screen-below-xl) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (@screen-below-medium) {
        grid-template-columns: 1fr;
      }
      .vrtx-contact-component {
        margin: 0;
      }
      br {
        display: none;
      }
    }
  }
}



// Person list page

.vrtx-person-search-hits {
  margin-top: 10px;
}
ul.vrtx-person-listing {
  margin-top: 30px;
  list-style: none;
  padding: 0;
  li {
    position: relative;
    min-height: 100px;
    margin-bottom: 30px;
    padding-left: 130px;
    @media (@screen-below-medium) {
      padding-left: 100px;
    }
    // Img and placeholder
    img,
    &:not(:has(img))::before {
      position: absolute;
      left: 0;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      @media (@screen-medium) {
        top: -5px;
      }
      @media (@screen-below-medium) {
        width: 80px;
        height: 80px;
      }
    }
    img {
      object-fit: cover;
      margin: 0;
    }
    &:not(:has(img))::before {
      content: "";
      background-color: #0001;
    }
    > span {
      display: inline-block;
      margin-right: 10px;
    }
    .vrtx-person-listing-name,
    .vrtx-person-listing-position {
      font-variation-settings: 'wght' 600;
      display: block;
    }
    .vrtx-person-listing-name {
      font-size: 2.4rem;
      line-height: 1.3;
    }
  }
}



// Person page
#vrtx-uh-person {
  #vrtx-content {
    @media (@screen-large) {
      margin: 0 10%;
    }
  }
  #vrtx-person-top-area {
    background-color: var(--uia-brun-1);
    border-radius: var(--radius);
    padding: 40px;
    @media (@screen-below-large) {
      padding: 25px;
    }
    h1 {
      margin-bottom: 15px;
      @media (@screen-medium) {
        grid-column: 2;
        font-size: 5rem;
        line-height: 1.1;
      }
      @media (@screen-below-medium) {
        line-height: 1.2;
      }
    }
    .vrtx-position-unit {
      margin-bottom: 30px;
    }
    .vrtx-position {
      font-variation-settings: 'wght' 600;
      font-size: 2.4rem;
      line-height: 1.3;
    }
    img {
      border-radius: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
      width: 300px;
      @media (@screen-medium) {
        grid-row: 1/5;
        max-width: 30vw; // Limit width on tablet
      }
      @media (@screen-below-medium) {
        order: -1;
      }
    }
    .vrtx-person-contact-info-list {
      margin-bottom: 30px;
      dt,
      dd {
        display: inline;
      }
      dt {
        margin-right: 10px;
      }
      dd {
        // Line-separator
        &::after {
          content: "";
          display: block;
          margin-bottom: 10px;
        }
      }
    }
    #vrtx-person-vcard {
      display: none; // Hide link to vcard download
    }
  }
  #vrtx-publications-wrapper {
    max-width: none;
  }
}
/*
#vrtx-person-top-area {
  position: relative;
  background-color: var(--uia-brun-1);
  border-radius: var(--radius);
  @media (@screen-large) {
    padding: 40px;
    &:has(img) {
      padding-left: 380px;
      min-height: 380px; // Cover image height in case little text
    }
  }
  @media (@screen-below-large) {
    padding: 25px;
    &:has(img) {
      padding-top: 300px;
    }
  }
  img {
    position: absolute;
    border-radius: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    @media (@screen-large) {
      width: 300px;
      left: 40px;
      top: 40px;
    }
    @media (@screen-below-large) {
      width: 250px;
      left: 25px;
      top: 20px;
    }
  }
  h1 {
    margin-bottom: 15px;
    @media (@screen-medium) {
      font-size: 5rem;
      line-height: 1.1;
    }
    @media (@screen-below-medium) {
      line-height: 1.2;
    }
  }
  .vrtx-person-contact-position {
    font-variation-settings: 'wght' 600;
    font-size: 2.4rem;
    line-height: 1.3;
  }
  .vrtx-unit {
    display: block;
    margin-bottom: 30px;
  }
  .vrtx-person-contact-info-list {
    margin-bottom: 0;
    dt,
    dd {
      display: inline;
    }
    dt {
      margin-right: 10px;
    }
    dd {
      &::after {
        content: "";
        display: block;
        margin-bottom: 10px;
      }
    }
  }
  .vrtx-person-contact-line {
    margin-bottom: 30px;
    .vrtx-label {
      font-variation-settings: 'wght' 600;
      margin-right: 10px;
    }
  }
  #vrtx-person-contact-info-press-photo-vcard {
    margin-top: 30px; // Due to contact-line above not always present
  }
  #vrtx-person-vcard {
    display: none; // Hide link to vcard download
  }
}
*/
