// uia naturmuseum.less

/*
Museum has its own header, footer, menu and color profile
Unique body class follows config in vrtx/decorating/config.txt
Header, footer and supplementary menu set up in vrtx/decorating/resources
*/

body.naturmuseum {
  // Green color profile
  --color-neutral-tint-95: var(--uia-gronn-1);

  // Remove frontpage row color selector
  .vrtx-edit-row.vrtx-frontpage-row {
    .admin-box-row-menu__element.menu__element_row_change_color {
      display: none;
    }
  }

  // Header
  // Logo is handled in header.less
  #header {
    @media (@screen-below-medium) {
      column-gap: 10px; // More space for logo
    }
  }
  #head-wrapper {
    --top-space: 55px;
    padding-top: var(--top-space);
    @media (@screen-below-medium) {
      --top-space: 45px;
    }
    // Parent (container for link back to uia)
    .parent {
      position: absolute;
      top: calc(-1 * var(--top-space)); // Subtract top-space
      background-color: var(--uia-gronn-2);
      margin-left: calc(-50vw + 50%);
      width: 100vw;
      height: 36px; // Balance with top-space
      // Inner
      .inner {
        .mixin-page-wrapper;
      }
      // Link to uia.no
      a {
        display: flex;
        gap: 5px;
        margin-top: 5px; // Tweak y-pos
        font-size: var(--font-size-small);
        color: white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        &:before {
          content: "";
          background: url("../images/arrow-white.svg") no-repeat center;
          background-size: contain;
          transform: rotate(180deg);
          width: 1em;
        }
      }
    }
  }
  // When menu or search are not open
  &:not(.header-search-visible, .sidebar-menu-wrapper-visible) {
    #head-wrapper {
      #header-tools {
        color: var(--uia-gronn-2);
        // Search and menu button
        .toggle-search::before {
          background-image: url("../images/search-green.svg");
        }
        .sidebar-menu-toggle::before {
          background-image: url("../images/menu-green.svg");
        }
      }
    }
  }
  // When menu or search are open
  &.header-search-visible,
  &.sidebar-menu-wrapper-visible {
    #head-wrapper {
      background-color: var(--uia-gronn-2);
    }
  }



  // Menu
  .sidebar-menu-wrapper {
    background-color: var(--uia-gronn-2);
  }

  // Buttons
  .button-loud,
  .button-large {
    background-color: var(--uia-gronn-2);
  }

  // Headings
  h1,
  .media-wide.intro .vrtx-box-content h2,
  .background-video.intro .text-box h2 {
    color: var(--uia-gronn-2);
  }

  // Article body
  .vrtx-article-body {
    border-bottom-color: var(--uia-gronn-2);
  }

  // Feed items
  .vrtx-feed li {
    background-color: var(--uia-gronn-1);
  }
  .row-all-colored {
    .vrtx-feed li {
      background-color: white;
    }
  }

  // Opening-hours and prices
  .visit-table {
    margin-top: 5px;
    @media (@screen-medium) {
      font-size: 2.4rem;
    }
    th,
    td {
      border-width: 1px 0;
    }
    th {
      padding-left: 0;
    }
  }

  // Social icon colors
  // Sharing
  .vrtx-social-components a.vrtx-email-friend::before {
    background-image: url("../images/social/mail-green.svg");
  }
  // Sharing and social list
  .vrtx-social-components a.facebook::before,
  .vrtx-social-list a.facebook,
  .vrtx-social-list a[href*=facebook] {
    background-image: url("../images/social/facebook-green.svg");
  }
  .vrtx-social-components a.twitter::before,
  .vrtx-social-list a.twitter,
  .vrtx-social-list a[href*=twitter] {
    background-image: url("../images/social/twitter-green.svg");
  }
  // Social list
  .vrtx-social-list a.youtube,
  .vrtx-social-list a[href*=youtube] {
    background-image: url("../images/social/youtube-green.svg");
  }
  .vrtx-social-list a.linkedin,
  .vrtx-social-list a[href*=linkedin] {
    background-image: url("../images/social/linkedin-green.svg");
  }
  .vrtx-social-list a.linkedin,
  .vrtx-social-list a[href*=linkedin] {
    background-image: url("../images/social/linkedin-green.svg");
  }
  .vrtx-social-list a.instagram,
  .vrtx-social-list a[href*=instagram] {
    background-image: url("../images/social/instagram-green.svg");
  }
  .vrtx-social-list a.tripadvisor,
  .vrtx-social-list a[href*=tripadvisor] {
    background-image: url("../images/social/tripadvisor-green.svg");
  }
  .vrtx-social-list a.snapchat,
  .vrtx-social-list a[href*=snapchat] {
    background-image: url("../images/social/snapchat-green.svg");
  }
  .vrtx-social-list a.pinterest,
  .vrtx-social-list a[href*=pinterest] {
    background-image: url("../images/social/pinterest-green.svg");
  }
  .vrtx-social-list a.tiktok,
  .vrtx-social-list a[href*=tiktok] {
    background-image: url("../images/social/tiktok-green.svg");
  }
}


