// x-general less


* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%; // 1rem = 10px
  line-height: 1.5;
  scroll-behavior: smooth;
  // Class can be used (by JS or other) to prevent smooth scroll in selected cases
  &.hard-scroll {
    scroll-behavior: auto;
  }
}

body {
  margin: 0;
}

img {
  margin-bottom: 20px;
  &[alt] {
    font-size: var(--font-size-tiny);
  }
}

figure {
  margin: 0 0 20px 0;
  p,
  img {
    margin: 0;
  }
}

img,
video {
  display: block; // Slight risk having this as default, but more typical for usage
  max-width: 100%;
  height: auto;
}

// Img exception for frontpage editor
img[src*="/frontpage-editor"] {
  display: initial;
}

iframe {
  max-width: 100%;
  border: none;
}



// Left-main in frontpage edit mode
#vrtx-frontpage.vrtx-mode-edit #main #left-main {
  display: none;
}



// Language-links
// Hidden in DOM and connected to global switch in header by head-language-link.js
// Many different ones. Just add here as needed.
#vrtx-change-language-link,
#vrtx-uh-person-change-language-link,
#vrtx-fs-studieprogram-frontpage-change-language-link,
#vrtx-fs-studieprogram-plan-change-language-link {
  display: none;
}



// Iframe video
.vrtx-media-player iframe,
iframe[src*=youtube],
iframe[src*=facebook] {
  // Set default aspect ratio
  // For vrtx player, ratio is redefined with js
  // and sent back through the style property (see x-base.js)
  --video-aspect-ratio: 16/9;
  width: 100%;
  max-width: var(--width-soft-max);
  height: auto;
  max-height: 700px;
  aspect-ratio: var(--video-aspect-ratio);
}

// Vrtx player portrait ratio. Class added with js if height > width
.vrtx-media-player iframe {
  &.portrait-ratio {
    max-width: 350px;
  }
}

// Vrtx player portrait version. Manual class.
.media-portrait-left,
.media-portrait-right {
  clear: both;
  margin: 40px 0;
  @media (@screen-medium) {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: var(--space);
    align-items: center;
  }
  .vrtx-media-player {
    iframe {
      // Set default/fallback aspect ratio for portrait (recalculated with js)
      --video-aspect-ratio: 9/16;
      display: block; // Remove bottom spacing due to default inline display
      margin-bottom: 15px;
    }
  }
}
.media-portrait-right {
  @media (@screen-medium) {
    grid-template-columns: 1fr 300px;
    .vrtx-media-player {
      order: 2;
    }
  }
}

// Vrtx player has separate print version for some reason
// Seems to only appear for streams
.vrtx-media-player-print {
  display: none;
}



// Nettskjema iframe
.nettskjema-iframe {
  border: 1px solid var(--color-neutral-tint-80);
}



// Forms
form {
  margin: 0;
}
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
legend {
  font-weight: bold;
  padding: 0;
}



// Search-form (include-component)
.vrtx-search-form {
  margin-bottom: 25px;
  fieldset,
  .field-wrapper {
    display: flex;
    gap: 10px;
    max-width: 500px;
    input {
      width: 100%;
    }
    button {
      flex-shrink: 0;
    }
  }
}



// Tables
table {
  border-collapse: collapse;
  margin-bottom: 40px;

  // Replaced 28 Mar 2025 by improved overflow handling
  // @media (@screen-below-large) {
  //   display: block;
  //   overflow: auto;
  // }
}
caption,
th {
  text-align: left;
}
caption {
  .mixin-h3;
}
thead {
  background: var(--color-neutral-tint-95);
}
th,
td {
  border: 1px solid var(--color-neutral-tint-80);
  padding: 10px;
}
table.no-border {
  th,
  td {
    border: none;
  }
}

// CK5 table is in figure and using figcaption for caption
figure.table {
  display: table;
  figcaption {
    display: table-caption;
    caption-side: top;
    margin: 0 0 5px 0;
  }
}

// Overflows: Class added by JS if table overflows available width
// Needs x-responsive-table.js
table.overflows {
  display: block; // Needed for overflow-scroll
  overflow: auto;
  width: var(--availableWidth); // Var added to style property by JS
  border-right: 2px dashed var(--color-neutral-tint-30);
  // Arrow indicator
  &::before {
    content: "→";
    position: absolute;
    background-color: white;
    padding: 0 5px;
    right: 17px; // Just inside dashed border
    margin-top: -30px;
  }
  &:has(caption)::before {
    margin-top: 5px;
  }
}

// Responsive table. Manually added class that breaks down content vertically
// Needs x-responsive-table.js
.responsive-table {
  span.responsive-content {
    display: none;
    @media (@screen-below-medium) {
      display: inline-block;
    }
  }
  @media (@screen-below-medium) {
    &,
    caption,
    thead,
    tbody,
    tr,
    th,
    td {
      display: block;
    }
    thead {
      display: none;
    }
    tr {
      border: 1px solid var(--color-neutral-tint-80);
      padding: 10px;
      &:not(:last-child) {
        border-bottom-width: 0;
      }
    }
    th,
    td {
      border: none;
      padding: 0;
    }
  }
}
// CK5
figure.table:has(.responsive-table) {
  @media (@screen-below-medium) {
    width: 100%;
  }
}



// Hidden except for screen-readers
.invisible,
.skip-link,
.offscreen-screenreader {
  position: absolute;
  left: -100000px;
}



// Error pages (404, 403)
body#error {
  #head-wrapper {
    margin-bottom: 30px;
  }
  // 404
  .error-404 {
    display: grid;
    gap: var(--space) calc(2 * var(--space));
    @media (@screen-large) {
      grid-template-columns: 1fr 1fr;
    }
    .search-form {
      display: flex;
      gap: 10px;
      max-width: 500px;
      margin-bottom: 25px;
      label {
        .invisible;
      }
      input {
        width: 100%;
      }
      button {
        flex-shrink: 0;
      }
    }
  }
}



// Frontpage admin row-color selector
// Temp. styles. Should probably be in vortex-frontend repo
.modali {
  // Increase modal size if contains color-options
  &:has(.admin-frontpage-color-option-container) {
    max-width: 80%;
  }
  // Container
  .admin-frontpage-color-option-container {
    align-self: stretch; // Override flex parent
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

    // Button (parent)
    .admin-frontpage-color-option {
      text-align: center;
      &:focus {
        outline-offset: 0;
      }
    }
    // Circle (svg)
    .admin-frontpage-color-option__circle {
      stroke: white;
      stroke-width: 2px;
      border-radius: 100%;
      outline: 2px solid #0004;
      transition: stroke .3s;
    }
    .admin-frontpage-color-option__circle--selected {
      outline-color: black;
    }
    // Title
    .admin-frontpage-color-option__title {
      padding: 2px 8px;
      border: 2px solid transparent;
      border-radius: 5px;
      transition: border-color .3s;
    }
    .admin-frontpage-color-option__title--selected {
      text-decoration: none;
      border-color: black;
    }
    // Button (parent) hover
    .admin-frontpage-color-option {
      &:hover {
        .admin-frontpage-color-option__circle {
          stroke: #0002;
          outline-color: #0008;
        }
      }
    }
  }
}
// Override vortex-frontend
.modali {
  // Increase modal size if contains color-options
  &:has(.admin-frontpage-color-option-container) {
    width: auto;
  }
  .admin-frontpage-color-option-container {
    width: auto;
  }
}



// Image admin view
#vrtx-image-preview {
  main {
    h2 {
      margin: 40px 0 10px;
    }
  }
}



