// uia base.less

// Xl
@screen-xl: ~"min-width: 1251px";
@screen-below-xl: ~"max-width: 1250px";

:root {
  // Radius
  --radius: 15px;
  --radius2: 30px;
  --radius5: 130px;

  // Colors
  --uia-color-text: #333;
  --uia-rod-1: #FEE7E8;
  --uia-rod-2: #C8102E;
  --uia-gronn-1: #E6F0E5;
  --uia-gronn-2: #03591D;
  --uia-bla-1: #E1EEF9;
  --uia-bla-2: #192343;
  --uia-brun-1: #F8EDDD;
  --uia-gul-2: #F6D738;

  // Colors, override X
  --color-neutral-tint-95: var(--uia-brun-1);
  --color-neutral-tint-opaque-95: var(--uia-brun-1);

  // Space, override X
  @media (@screen-below-medium) {
    --space: 30px;
  }
}



// Global sub-menu (at page bottom) not in use
#global-sub-menu {
  display: none;
}



// Links
a {
  text-decoration-thickness: 2px;
  text-underline-offset: .1em;
}
// Override X
// Moved arrow from :before left to :after right.
.mixin-arrow-link {
  font-variation-settings: 'wght' 550;
  position: relative;
  padding-right: 50px; // Space for arrow after
  &::before {
    display: none; // Remove arrow before
  }
  &::after {
    content: "";
    background: url("../images/arrow.svg") no-repeat center;
    background-size: contain;
    position: absolute;
    margin-top: 1px; // Tweak y-position
    margin-left: 8px;
    width: 18px;
    height: 1.5em;
    transition: all .3s;
  }
  &:hover {
    &::after {
      margin-left: 18px;
    }
  }
}
// Local mixin
// Creates arrow markers before text
// Mainly for use on links in ul
// Needs padding left on self or parent
.mixin-arrow-marker {
  position: relative;
  &::before {
    content: "";
    background: url("../images/arrow.svg") no-repeat center;
    background-size: contain;
    position: absolute;
    left: -30px; // Assumes padding-left on parent
    width: 18px;
    height: 1.5em;
    transition: all .3s;
  }
  &:hover {
    &::before {
      left: -20px;
    }
  }
}



// Buttons, inputs, selects
input,
button,
select,
.mixin-button-link {
  font-variation-settings: 'wght' 550;
  border-radius: var(--radius2);
  border-width: 2px;
  padding: 15px 25px;
}
input[type=checkbox],
input[type=radio] {
  border-width: unset;
  padding: unset;
  accent-color: var(--uia-rod-2);
}
.mixin-button-link {
  padding-right: 60px;
}
.button-loud,
.button-large {
  background-color: var(--uia-rod-2);
}
