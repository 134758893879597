// uia typography.less


body {
  font-family: "acumin-variable", sans-serif;
  font-variation-settings: 'wght' 400;
  color: var(--uia-color-text);
}

h1 {
  color: var(--uia-rod-2);
  @media (@screen-medium) {
    font-size: 6rem;
    line-height: 1.1;
  }
}

/*
Due to how Adobe fonts are set up, all bolds and italics need to be reconfigured
from using font-weight and font-style to using font-variations-settings.
*/

// Bolds
h1,
h2,
.mixin-h2,
h3,
.mixin-h3,
h4,
h5,
h6,
strong,
b,
th,
dt,
legend,
.vrtx-tags .title,
.vrtx-authors .vrtx-authors-prefix,
.vrtx-date-info .published-date-label,
.vrtx-date-icon .vrtx-date-icon-day,
.vrtx-feed .feed-title,
.vrtx-feed .authors,
.vrtx-image-listing .vrtx-image-title a,
.vrtx-person-contactinfo .vrtx-label,
#footer-wrapper h2,
#footer-wrapper .menu-label,
#header-tools #header-language-tooltip p:first-of-type,
.vrtx-frontpage-box.link-special .vrtx-box-content,
.mixin-arrow-link,
.vrtx-subfolder-menu .menu-title,
.vrtx-toc-header,
span.vrtx-toc-header,
#vrtx-publication-tabs div[id*=vrtx-publication-tab-] .vrtx-title,
.vrtx-person-listing tbody .vrtx-person-listing-name a:not(.vrtx-image)
{
  font-weight: normal;
  font-variation-settings: 'wght' 600;
}

// Italics
i,
em,
blockquote p:first-child,
cite
{
  font-style: normal;
  font-variation-settings: 'slnt' -12;
}