// uia feeds and lists.less



// Event time and place elements
// Used in events feed, on list page and on event page.
.vrtx-time-and-place-container {
  .separator-time-and-place {
    margin-right: 5px;
  }
  .location {
    &::before {
      content: "";
      background: url("../images/map-pin-red.svg") no-repeat center;
      padding: 3px 11px;
      margin-right: 10px;
    }
  }
}
#vrtx-event-listing {
  .vrtx-time-and-place-container {
    font-variation-settings: 'wght' 600;
    .time-and-place {
      font-variation-settings: 'wght' 400;
    }
  }
}



// Event-type (in feed, on list-page and on event page)
.event-type {
  span {
    display: inline-block;
    background-color: #0001;
    border-radius: var(--radius2);
    padding: 5px 12px;
    margin: 0 5px 10px 0;
  }
}



// Feed and event component
.vrtx-feed,
.vrtx-event-component {
  // Top-spacing for anything after a feed, such as another feed, in the same box
  & + * {
    margin-top: var(--space);
  }
  // General rules for vertical layout. Some overriden for columns.
  .vrtx-list-item-content {
    padding: 25px;
    @media (@screen-medium) {
      &:not(:first-child) {
        padding-left: 0; // Left space from flex container when next to image
      }
    }
  }
  img {
    aspect-ratio: 16/9;
    border-radius: 0;
    margin: 0;
    @media (@screen-medium) {
      height: 100%; // Fill list-item height when next to text
    }
  }
}



// Feed and event-component when in column-layout
[class*=column-feed] {
  .vrtx-feed,
  .vrtx-event-component {
    .vrtx-list-item-content {
      padding: 25px;
    }
    img {
      height: auto;
    }
  }
}



// Feed and event-component list-items
.vrtx-feed li,
.vrtx-event-component-item {
  background-color: var(--uia-brun-1);
  border-radius: var(--radius);
  overflow: hidden;
}



// In automatically colored row
// Feed list-items get white bg
// Event list-items get white bg if in column-layout
.row-all-colored {
  .vrtx-feed li,
  [class*=column-feed] .vrtx-event-component-item {
    background-color: white;
  }
}



// Feed
.vrtx-feed {
  .feed-title {
    margin-bottom: 25px;
  }
  .published-date {
    color: inherit;
  }
}



// Featured feed item
// Styled to match img-special
.featured-feed-item {
  .vrtx-feed li:first-child,
  .vrtx-event-component-item:first-child .vrtx-event-component-main {
    @media (@screen-medium) {
      gap: 0;
      .vrtx-list-item-content {
        padding: 10%;
      }
    }
  }
}
// In case event-type disputas (but shouldn't be used for that)
.featured-feed-item .vrtx-event-component-item:first-child {
  // Only if picture present
  &.with-picture {
    &[data-event-type*="Disputas"],
    &[data-event-type*="Disputation"] {
      @media (@screen-medium) {
        .vrtx-list-item-content {
          padding-left: 0;
        }
        .vrtx-image {
          padding-bottom: 15px;
        }
      }
    }
  }
}



// Event-component general elements
.vrtx-event-component {
  // Hide month if today
  .vrtx-date-icon-today + .vrtx-date-icon-month {
    visibility: hidden;
  }
  .vrtx-list-item-content {
    display: flex;
    flex-wrap: wrap;
  }
  .vrtx-event-component-title {
    flex-basis: 100%;
  }
  .vrtx-event-component-misc {
    margin: 0 15px 20px 0;
  }
  .vrtx-time-and-place-container {
    margin: 0;
  }
  .event-type {
    order: 3;
    margin-bottom: 10px;
    span {
      margin-top: -5px; // Counteracts padding to baseline-align
    }
  }
  .vrtx-event-component-introduction {
    order: 4;
    flex-basis: 100%;
    p {
      max-width: var(--width-text-max);
    }
  }
}



// Event-component in column-layout
[class*=column-feed] {
  .vrtx-event-component {
    // Date-icon
    .vrtx-date-icon {
      background-color: var(--uia-color-text);
      color: white;
      border-radius: var(--radius) 0 0;
    }
    // Unique image style for event-type disputas to fit portrait format better
    .vrtx-event-component-item {
      &[data-event-type*="Disputas"],
      &[data-event-type*="Disputation"] {
        .vrtx-image {
          display: block;
          // Ratio moved to img parent to maintain same image-height as other event-types
          aspect-ratio: 16/9;
          padding-top: 15px;
        }
        img {
          width: auto;
          height: 100%;
          aspect-ratio: 1/1;
          border-radius: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}



// Event-component in vertical / non-column layout
// Unique calendar-ish styling without images.
.vrtx-frontpage-box:not([class*=column-feed]) {
  .vrtx-event-component {
    gap: 0;
  }
  .vrtx-event-component-picture {
    display: none; // Images not used in this version
  }
  .vrtx-event-component-item {
    background: none;
    position: relative;
    border-bottom: 2px solid;
    border-radius: 0;
    transition: all .3s;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    @media (@screen-medium) {
      column-gap: 30px;
    }
    .vrtx-event-component-main {
      align-items: start;
    }
    .vrtx-list-item-content {
      flex-basis: unset;
      flex-grow: 1;
      padding: 0;
      margin-top: 10px;
      @media (@screen-medium) {
        margin-right: 50px; // Arrow-space
      }
    }
  }
  .vrtx-date-icon {
    position: static; // No image, so no absolute
    background-color: transparent;
    border: 2px solid;
    border-radius: var(--radius);
    padding-top: 5px;
    font-size: 3.6rem;
    justify-content: center;
    flex-shrink: 0;
    flex-wrap: nowrap;
    flex-basis: 90px; // Min-width
    @media (@screen-medium) {
      align-self: center;
    }
  }
  .vrtx-date-icon-month {
    font-variation-settings: 'wght' 600;
    font-size: 2rem;
  }
  // Heading link
  .vrtx-event-component-title.summary {
    margin-bottom: 10px;
    @media (@screen-below-medium) {
      padding-right: 2em; // Arrow-space (not absolute on small screens)
    }
    // Pseudo link hack to cover entire event
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    // Arrow
    &::after {
      content: "";
      background: url("../images/arrow.svg") no-repeat center;
      background-size: contain;
      position: absolute;
      width: 24px;
      height: 1.5em;
      transition: all .3s;
      @media (@screen-medium) {
        right: 10px;
        top: 10px;
      }
      @media (@screen-below-medium) {
        margin-left: 10px;
      }
    }
    &:hover {
      &:after {
        @media (@screen-medium) {
          right: 0;
        }
      }
    }
  }
  .location {
    a {
      // Enable click by z-indexing above Pseudo link hack
      position: relative;
      &:hover {
        text-decoration: underline;
        background-color: #0001;
      }
    }
  }
}



// Event list page
#vrtx-event-listing {
  .vrtx-list-item-content {
    display: grid;
    align-content: start;
  }
  .event-type {
    margin-bottom: 5px;
  }
  .introduction {
    order: 4;
  }
}



// vrtx-feed-link. "rss strøm fra denne siden"
#vrtx-feed-link,
.vrtx-feed-link {
  display: none;
}



// Search page
#vrtx-searchview {
  .vrtx-big-search,
  #vrtx-search-did-you-mean,
  .hits {
    @media (@screen-medium) {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
  .vrtx-big-search {
    border-radius: var(--radius) var(--radius) 0 0;
    @media (@screen-medium) {
      padding-top: 40px;
    }
  }
  .hits {
    border-radius: 0 0 var(--radius) var(--radius);
    @media (@screen-medium) {
      padding-bottom: 40px;
    }
  }
}



// Pager
.vrtx-paging-wrapper {
  a {
    &.marked,
    &.vrtx-marked {
      background-color: var(--uia-rod-2);
    }
  }
}



// Jobbnorge feed. Ledige stillinger
.jobbnorge-feed {
  .vrtx-feed {
    ul {
      gap: 0;
      padding-left: 30px;
    }
    li {
      background: none;
      border-radius: 0;
      overflow: visible;
    }
    .vrtx-list-item-content {
      padding: 0;
    }
    a {
      .mixin-arrow-marker;
      font-size: inherit;
      font-variation-settings: 'wght' 400;
    }
  }
}


