// uia exchange.less


// List page
#vrtx-uh-exchange-listing {
  .vrtx-exchange-destination-entry {
    margin-bottom: 25px;
    a {
      font-size: 2.4rem;
      font-variation-settings: 'wght' 600;
      display: block;
      width: fit-content;
    }
    span {
      margin-right: 10px;
    }
  }
}


// Destination page
#vrtx-uh-exchange-destination {
  #vrtx-content {
    @media (@screen-large) {
      margin: 0 10%;
    }
  }
  .vrtx-introduction {
    margin-bottom: 30px;
  }
  .vrtx-uh-facts-horizontal {
    margin-bottom: 60px;
    dt,
    dd {
      display: inline-block;
    }
    dt::after {
      content: ":";
    }
    dd {
      margin-right: 15px;
    }
  }
  #vrtx-uh-exchange-content {
    > div {
      margin-bottom: 60px;
      &:not(.vrtx-uh-infobox) {
        max-width: var(--width-text-max);
      }
    }
  }
  // Infobox with image and intro
  .vrtx-uh-infobox {
    background-color: var(--uia-brun-1);
    padding: 5%;
    border-radius: var(--radius);
    overflow: hidden;
    @media (@screen-large) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: start;
      gap: 80px;
    }
    .vrtx-introduction-image {
      margin-bottom: 30px;
      @media (@screen-large) {
        order: 2;
        margin-right: -20%;
        margin-bottom: 0;
        img {
          width: 100%;
          aspect-ratio: 3/2;
          object-fit: cover;
        }
        .vrtx-imagetext {
          padding-right: 10%;
        }
      }
    }
    .vrtx-uh-facts-vertical {
      .vrtx-field-city {
        display: inline-block;
      }
      dt.vrtx-field-city {
        &::after {
          content: ":";
        }
      }
    }
    .vrtx-uh-field-description {
      h2 {
        // Remove redundant h2 right after other h2
        display: none;
      }
    }
  }
  .vrtx-field-application {
    margin-top: 30px;
  }
  .vrtx-field-stories {
    max-width: var(--width-soft-max);
    .vrtx-feed img {
      aspect-ratio: 3/2;
    }
    .read-more {
      margin-top: 25px;
    }
  }
}