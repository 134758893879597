// uia research-groups-and-projects.less


// Alphabetical project and research group listing
// Note: There is one of these per letter
.vrtx-alphabetical-project-listing,
.vrtx-alphabetical-research-group-listing {
  padding: 0;
  &,
  ul {
    list-style: none;
  }
  font-size: 3.2rem; // Alphabet letter size
  ul {
    font-size: var(--font-size-base);
  }
  a {
    .mixin-arrow-marker;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}



// Project
#vrtx-structured-project {
  #vrtx-content {
    display: block; // Disable columns
    @media (@screen-large) {
      max-width: var(--width-soft-max);
      margin: 0 auto;
    }
  }
  h1 {
    max-width: var(--width-soft-max);
    margin-bottom: 35px; // Due to variety in what comes next
  }
  // Project category
  .sub-title {
    background-color: #0001;
    border-radius: var(--radius2);
    width: fit-content;
    padding: 5px 12px;
    margin-bottom: 35px;
  }
  .vrtx-introduction {
    margin-top: -10px; // Subtract h1 spacing
  }
  // Project duration etc.
  .vrtx-byline {
    div {
      margin-bottom: 10px;
    }
    @media (@screen-medium) {
      dt,
      dd {
        display: inline;
      }
      dt {
        margin-right: 15px;
      }
    }
  }
  .vrtx-article-body {
    border: none;
    padding: 0;
    margin-bottom: 50px;
  }
  .vrtx-comp-person-list {
    margin-bottom: 50px;
    list-style: none;
    a {
      .mixin-arrow-marker;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  // Publications (Cristin). Not always present.
  #vrtx-publications-wrapper {
    margin-bottom: 60px;
  }
  // Bottom area, with project group etc.
  // Calculated to full-width row.
  .vrtx-grey-lower-banner {
    background-color: var(--uia-brun-1);
    width: 100vw;
    margin-bottom: var(--space);
    margin-left: calc(50% - 50vw);
    padding-left: calc(50vw - 50%);
    padding-top: calc(2 * var(--space));
    padding-bottom: calc(2 * var(--space));
    padding-right: 50px;
    @media (@screen-below-medium) {
      padding-right: 15px;
    }
    > * {
      max-width: var(--width-soft-max);
      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }
    h2 {
      margin-bottom: 20px;
    }
  }
}