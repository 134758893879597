// uia footer.less

#footer-wrapper {
  background-color: var(--uia-bla-2);
  .row {
    position: relative;
    padding-bottom: 80px; // Space for logo and slogan

    @media (@screen-large) {
      column-gap: 120px;
    }
    @media (@screen-medium) {
      display: flex;
      justify-content: space-between;
    }
    @media (@screen-below-medium) {
      padding-bottom: 100px; // Space for logo and slogan
    }
    // Logo and slogan
    &::before,
    &::after {
      position: absolute;
      bottom: 0;
    }
    &::before {
      content: "";
      background: url("../images/logo-name-only-white.svg") no-repeat;
      background-size: contain;
      width: 110px;
      height: 42px;
      @media (@screen-below-medium) {
        left: 15px;
      }
    }
    &::after {
      content: "Sammen om kunnskap";
      font-variation-settings: 'wght' 150;
      font-size: 2.2rem;
      line-height: 1.1;
      text-align: right;
      width: 150px;
      right: 50px;
      @media (@screen-below-medium) {
        right: 15px;
      }
    }
  }
  // Footer content
  .footer-content {
    @media (@screen-large) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 80px;

      > div:nth-child(4) {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: -1;
      }
    }
  }
  .social {
    margin-top: 10px;
  }
  .vrtx-social-list {
    li {
      @media (@screen-large) {
        margin-right: 25px;
      }
    }
    a {
      background-position: center;
      border-radius: 0;
      width: 26px;
      height: 26px;
      &.facebook {
        background-image: url("../images/social/facebook-white.svg");
      }
      &.instagram {
        background-image: url("../images/social/instagram-white.svg");
      }
      &.linkedin {
        background-image: url("../images/social/linkedin-white.svg");
      }
      &.snapchat {
        background-image: url("../images/social/snapchat-white.svg");
      }
      &.tiktok {
        background-image: url("../images/social/tiktok-white.svg");
      }
      &.twitter {
        background-image: url("../images/social/x-white.svg");
      }
      &.youtube {
        background-image: url("../images/social/youtube-white.svg");
      }
    }
  }
  // Login area
  .responsible-login {
    flex-shrink: 0;
    margin-bottom: 30px;
  }
  // Admin dropdown
  .vrtx-dropdown-component {
    // No min-width aligns element to right (but allows content to wrap)
    min-width: 0;
    // Override local button style
    button {
      font-variation-settings: 'wght' 400;
    }
  }
  @media (@screen-medium) {
    .vrtx-dropdown-component {
      position: relative;
    }
    .vrtx-dropdown-wrapper {
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }
}
// English footer
html[lang=en] #footer-wrapper {
  .row {
    &::after {
      content: "Creating knowledge together";
    }
  }
}