// uia publications.less


#vrtx-publication-tabs {
  // Selector links
  > ul {
    a {
      border-width: 2px;
      border-radius: var(--radius2);
      &[aria-expanded=true] {
        background-color: var(--uia-rod-2);
      }
    }
  }
}
// Publication list
ul.vrtx-external-publications {
  li {
    background-color: var(--uia-brun-1);
    border-radius: var(--radius);
    padding: 20px 25px 25px;
    font-size: var(--font-size-small);
  }
  .vrtx-title {
    font-size: 2rem;
    line-height: 1.3;
    margin: 3px 0;
  }
}
// Summary container
.vrtx-publication-summary {
  // Link to show summary. Note: has same class as parent
  a {
    margin-top: 10px;
    border-width: 2px;
    border-radius: var(--radius2);
    &::before {
      display: none;
    }
    &::after {
      content: "";
      flex-shrink: 0;
      background: url("../images/plus.svg") no-repeat center;
      background-size: contain;
      height: 1em;
      width: 14px;
      transition: all .3s;
    }
    &[aria-expanded=true] {
      &::after {
        transform: rotate(45deg);
      }
    }
    &:hover {
      background-color: white;
    }
  }
}
