// uia general.less


#crisis-banner,
#crisis-banner-demo {
  background-color: var(--uia-gul-2);
  font-variation-settings: 'wght' 400;
  * {
    color: black;
  }
  .crisis-banner-inner {
    &::before {
      background-image: url("../images/alert-2.svg");
    }
  }
}



// Enable background-video
.vrtx-background-video {
  &::before {
    display: none;
  }
  video {
    opacity: 1;
  }
}



// Breadcrumbs
#breadcrumbs {
  span {
    margin-right: 10px;
    &::after {
      background-image: url("../images/arrow.svg");
      background-size: contain;
      order: -1;
      width: 14px;
      margin: 0 7px 0 5px;
      transform: rotate(180deg);
    }
  }
}
// When search or menu open
body.header-search-visible,
body.sidebar-menu-wrapper-visible {
  #vrtx-breadcrumb-wrapper {
    margin-top: 10px;
  }
}
// On the-frontpage
.the-frontpage #breadcrumbs {
  display: none;
}
// When hero present..
body:has(.hero),
body.has-hero {
  // hide breadcrumbs. probably unnecessary, since hero only used on frontpage
  #breadcrumbs {
    display: none;
  }
}
// On search result page
// Inner vrtx-breadcrumb-wrapper not present (except for lang=en)
#vrtx-searchview {
  #breadcrumbs {
    margin-bottom: 20px;
  }
}



// Menu
.sidebar-menu-wrapper {
  background-color: var(--uia-rod-2);
  color: white;
  .sidebar-global-menu {
    border: none;
    max-width: 750px;
  }
  // Hide front page (= parent-folder on sub level, such as /english and /naturmuseum)
  .root-folder,
  .parent-folder {
    display: none;
  }
  a {
    border-radius: 100px; // High enough to always be pill-shape
    padding-right: 40px;
    transition-timing-function: ease-out;
    &:hover {
      background-color: #0002;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  // Primary menu
  .vrtx-tab-menu {
    a {
      font-variation-settings: 'wght' 600;
      @media (@screen-medium) {
        font-size: 3rem;
      }
      @media (@screen-below-medium) {
        font-size: 2rem;
      }
    }
  }
  // Supplementary menu
  #supplementary-menu {
    display: block;
  }
}



// Image general rules
.main img {
  border-radius: var(--radius);
}



// Video and associated iframes
video,
.vrtx-media-player iframe,
iframe[src*=youtube],
iframe[src*=facebook] {
  border-radius: var(--radius);
}



// Manually added class for top-space. Used in footer and more.
.top-space {
  margin-top: 10px;
}



// Navigation-links
.navigation-links {
  ul {
    gap: 20px;
  }
  li {
    a,
    a:hover {
      border-color: transparent;
    }
    strong {
      font-size: 2.4rem;
      line-height: 1.3;
    }
    // If strong (heading) present
    a:has(strong) {
      padding-bottom: 20px;
    }
    // Img for icons only
    img {
      border-radius: 0;
      margin: 5px 0 10px;
      height: 50px;
      opacity: .8;
    }
    // Variant with icons. Poor support in FF, but output still ok.
    &:has(img) {
      a {
        padding-bottom: 40px;
        &::after {
          top: auto;
          bottom: 30px;
        }
      }
      strong {
        margin-bottom: 10px;
      }
    }
  }
}



// Linklist
// Manually added class on ul to get arrows instead of bullets
// Note: This class is local. Version in X is "link-list"
.linklist {
  li {
    width: fit-content;
    // If has link, remove list bullet and add link arrow
    &:has(a) {
      list-style: none;
      // Note: mixin used on li
      .mixin-arrow-marker;
    }
    a {
      font-variation-settings: 'wght' 600;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      // In case of read-more (which shouldn't be used), remove arrow
      &.read-more {
        padding-right: 0;
        &::after {
          display: none;
        }
      }
    }
  }
}



// Accordions
details.accordion-wrapper {
  border: 2px solid;
  border-radius: var(--radius);
  margin-bottom: 20px;
  max-width: var(--width-text-max);
  summary,
  .accordion {
    width: auto;
  }
  summary:focus {
    outline-offset: -10px;
  }
  .accordion {
    padding: 12px 50px 16px 25px; // Right space for icon
    margin: 0;
    &::before {
      background-image: url("../images/plus.svg");
      background-size: contain;
      left: auto;
      right: 20px;
    }
  }
  h2.accordion {
    font-size: 2.8rem;
    @media (@screen-below-medium) {
      font-size: 2.6rem;
    }
  }
  h3.accordion {
    @media (@screen-below-medium) {
      font-size: 2.2rem;
    }
  }
  &[open] {
    .accordion {
      &::before {
        transform: rotate(45deg);
      }
    }
  }
  .accordion-content {
    padding: 0 25px 15px;
  }
}
// Consent-settings accordion
.consent-settings {
  details.accordion-wrapper {
    max-width: none;
    border: none;
    // Heading
    .accordion {
      padding-right: 0;
      padding-left: 30px;
      &::before {
        left: 0;
        height: 1.5em;
      }
    }
    .accordion-content {
      padding: 0 0 15px 0;
    }
  }
}



// Introduction
.vrtx-introduction {
  font-variation-settings: 'wght' 300;
  // Limit text-width
  * {
    max-width: var(--width-soft-max);
  }
  ul,
  div,
  form  {
    font-size: var(--font-size-base);
  }
  form {
    font-variation-settings: 'wght' 400;
  }
}



// Blockquote
blockquote {
  &::before {
    content: "";
    background: url("../images/quote.svg") no-repeat;
    background-size: contain;
    display: block;
    width: 45px;
    height: 50px; // Adjusts spacing below
  }
  p:first-child {
    font-variation-settings: 'slnt' -12, 'wght' 600;
  }
}



// Grid container
.grid-container {
  margin-bottom: calc(2 * var(--space));
  &.row-all-colored {
    @media (@screen-below-medium) {
      padding-top: 40px;
    }
  }
}



// Frontpage hero
.hero {
  background-color: var(--uia-brun-1);
  border-radius: 0 0 0 var(--radius5);
  color: var(--uia-rod-2);
  .vrtx-frontpage-box {
    display: grid;
    @media (@screen-large) {
      grid-template-columns: 1fr 1fr;
    }
    .vrtx-frontpage-box-picture {
      order: 2;
      align-self: end;
    }
    img {
      aspect-ratio: 3/2;
      object-fit: contain;
      object-position: center bottom;
      border-radius: 0;
      margin: 0;
      @media (@screen-below-large) {
        width: 500px; // Limited by max-width below this
        margin: 0 auto;
      }
    }
    .vrtx-box-content {
      align-self: center;
      @media (@screen-large) {
        padding-left: 10%;
        padding-bottom: 40px;
      }
      @media (@screen-medium) {
        h2 {
          font-size: 5rem;
          line-height: 1.2;
          margin-bottom: 30px;
        }
      }
    }
    .read-more {
      &::after {
        background-image: url("../images/arrow-red.svg");
      }
    }
  }
}
body:not(.vrtx-mode-edit) {
  .hero {
    // Negative margin to underlap header. Large value to handle menu open/close.
    margin-top: -2000px;
    padding-top: 2020px;
    overflow: hidden;
  }
}



// Frontpage media-wide & background-video
.grid-container.media-wide,
.grid-container.background-video {
  img,
  video {
    border-radius: 0;
  }
}
// Text content box
.grid-container.media-wide .vrtx-box-content,
.grid-container.background-video .text-box {
  @media (@screen-large) {
    border-radius: var(--radius);
  }
}
// Intro versions
.grid-container.media-wide.intro,
.grid-container.background-video.intro {
  img,
  video,
  .vrtx-frontpage-box {
    @media (@screen-large) {
      min-height: 700px;
    }
  }
}
// Intro versions text content box
.grid-container.media-wide.intro .vrtx-box-content,
.grid-container.background-video.intro .text-box {
  padding-bottom: 0;
  @media (@screen-large) {
    border-radius: 0 var(--radius) 0 0;
    left: 0;
    bottom: 0;
    width: 70vw;
    padding: 40px 60px 0 50px;
    font-size: 2.4rem;
  }
  @media (@screen-oversized) {
    padding-left: calc(50px + (100vw - var(--width-total-max))/2);
  }
  h2,
  p {
    margin-bottom: 20px;
  }
  h2 {
    color: var(--uia-rod-2);
    @media (@screen-large) {
      font-size: 4rem;
      line-height: 1.2;
    }
  }
  .read-more,
  .button,
  .button-large,
  .button-loud {
    font-size: var(--font-size-base);
  }
}
// Intro video version
.grid-container.background-video.intro {
  .pause-video {
    left: auto;
    right: 0;
  }
}



// Item-special
.vrtx-frontpage-box {
  &.img-special-left,
  &.img-special-right,
  &.box-special,
  &.link-special {
    border-radius: var(--radius);
    overflow: hidden;
  }
  &.img-special-left,
  &.img-special-right,
  &.box-special {
    .vrtx-box-content {
      @media (@screen-below-medium) {
        padding: 30px 25px;
      }
    }
  }
  &.img-special-left,
  &.img-special-right {
    img {
      aspect-ratio: 16/9;
      border-radius: 0;

      // If svg image
      &[src*=".svg"] {
        aspect-ratio: auto;
        width: auto;
        height: auto;
        justify-self: center;
        align-self: center;
        padding: 30px 25px;
        @media (@screen-below-medium) {
          padding-bottom: 0;
        }
      }
    }
  }
  &.box-special {
    blockquote {
      p:first-child {
        @media (@screen-medium) {
          font-size: 3rem;
        }
        ~ * {
          font-size: inherit;
        }
      }
    }
  }
  &.link-special {
    border-width: 2px;
    @media (@screen-below-medium) {
      padding-left: 25px;
    }
    a::after {
      background-color: transparent;
    }
  }
}



// Custom frontpage box class to place image caption on right
// Used for museum map legend
.caption-right {
  figure {
    display: grid;
    gap: 20px 40px;
    @media (@screen-medium) {
      grid-template-columns: 2fr 1fr;
    }
    figcaption {
      font-size: inherit;
      color: inherit;
    }
  }
}



// Subfolder menu
.main .vrtx-subfolder-menu {
  border-radius: var(--radius);
  column-width: 300px; // Limit to three columns
  column-gap: 60px;
  padding: 20px 25px 30px;
}
// Custom subject list variant with depth=[2] and first level hidden
.main .subject-list .vrtx-subfolder-menu {
  ul ul {
    padding-left: 0;
  }
  > ul > li > a {
    display: none; // Hide first level
  }
}



// Facts-container
.vrtx-facts-container {
  border-radius: var(--radius);
}



// Article body
.vrtx-article-body {
  border-bottom-color: var(--uia-rod-2);
  blockquote {
    margin: 50px 0;
    p:first-child {
      @media (@screen-medium) {
        font-size: 3rem;
      }
      &::before {
        background-image: url("../images/quote-red.svg");
      }
    }
  }
}



// Hide "share on Facebook" etc
#main .vrtx-social-components {
  display: none;
}



// Media player portrait version
.media-portrait-left,
.media-portrait-right {
  blockquote {
    margin: 30px 0;
    p:first-child {
      @media (@screen-medium) {
        font-size: 2.6rem;
      }
    }
  }
}



// Article and event page
#vrtx-structured-article,
#vrtx-structured-event {
  .vrtx-article-body {
    @media (@screen-medium) {
      font-size: 2.2rem;
      font-variation-settings: 'wght' 300;
      h3 {
        font-size: 2.6rem;
      }
      // Font-size exceptions
      .button,
      .button-large,
      .button-loud,
      table,
      .vrtx-contact-component,
      .vrtx-facts-container {
        font-size: var(--font-size-base);
      }
      .vrtx-facts-container {
        h3 {
          font-size: var(--font-size-large-05);
        }
      }
    }
    @media (@screen-oversized) {
      .image-right {
        margin-right: -400px;
      }
      .vrtx-facts-container.vrtx-container-right {
        margin-right: -500px;
      }
    }
    .image-center {
      img,
      video {
        border-radius: 0;
      }
      figcaption {
        @media (@screen-large) {
          border-radius: var(--radius);
          font-variation-settings: 'wght' 600;
        }
      }
    }
  }
}



// Article page
#vrtx-structured-article {
  .publish-info {
    margin-bottom: 50px;
    .vrtx-authors {
      font-variation-settings: 'wght' 600;
    }
    .published-date-label {
      font-variation-settings: 'wght' 400;
    }
  }
  .vrtx-article-body {
    border-bottom: none; // Sharing removed, so no separator needed
  }
}



// Event page
#vrtx-structured-event {
  .vrtx-byline {
    max-width: var(--width-soft-max);
    @media (@screen-medium) {
      margin-bottom: 70px;
    }
  }
  .vrtx-time-and-place-container {
    font-size: 2.4rem;
    font-variation-settings: 'wght' 600;
  }
  .time-and-place,
  .event-type,
  .vrtx-number-of-comments-add-event-container {
    font-size: var(--font-size-base);
    font-variation-settings: 'wght' 400;
  }
  .time-and-place {
    display: block;
    margin-bottom: 10px;
  }
  .separator-time-and-place {
    display: none; // comma after time
  }
  .location {
    display: block;
    margin-bottom: 20px;
  }
  .event-type {
    margin-bottom: 10px;
  }
}



// Library search
.library-search {
  background-color: var(--uia-brun-1);
  border-radius: var(--radius);
  padding: 5%;
  @media (@screen-below-medium) {
    padding: 30px 25px;
  }
  form {
    margin-bottom: 30px;
  }
  fieldset {
    display: flex;
    gap: 10px;
    max-width: var(--width-text-max);
    input {
      width: 100%;
      &::placeholder {
        font-variation-settings: 'wght' 400;
      }
    }
    button {
      flex-shrink: 0;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
    margin: 0 20px 10px 0;
  }
}
.row-all-colored .library-search {
  background-color: white;
}



// 404 page
.error-404 {
  .intro {
    font-size: 3rem;
    font-variation-settings: 'wght' 300;
    @media (@screen-below-medium) {
      font-size: 2.4rem;
    }
  }
  .search-title {
    margin-bottom: 10px;
  }
}
